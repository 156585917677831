// 1. Import the extendTheme function
import { extendTheme } from "@chakra-ui/react";
import "@fontsource/lora/600-italic.css";
import "@fontsource/oxygen/400.css";
import "@fontsource/oxygen/700.css";

const config = {
  useSystemColorMode: false,
  initialColorMode: "light",
};

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  gray: {
    50: "#f5f5f5",
    100: "#dbdbdb",
    200: "#c2c2c2",
    300: "#a8a8a8",
    400: "#8f8f8f",
    500: "#767676",
    600: "#5c5c5c",
    700: "#434343",
    800: "#292929",
    900: "#101010",
  },
  orange: {
    50: "#f6e0da",
    100: "#ebbeb2",
    200: "#e19b89",
    300: "#d77a61",
    400: "#cd5637",
    500: "#a7452a",
    600: "#7e3420",
    700: "#552316",
    800: "#2d120b",
    900: "#180a06",
  },
  red: {
    50: "#fdeded",
    100: "#f8bfbf",
    200: "#f49090",
    300: "#ef6262",
    400: "#ea3434",
    500: "#d51616",
    600: "#a71111",
    700: "#790c0c",
    800: "#4a0808",
    900: "#1c0303",
  },
  teal: {
    50: "#dde8ee",
    100: "#bcd2dc",
    200: "#9abbcb",
    300: "#78a4ba",
    400: "#568da9",
    500: "#457187",
    600: "#345565",
    700: "#223844",
    800: "#111c22",
    900: "#090e11",
  },
};

const sizes = {
  container: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1920px",
  },
};

const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  "2xl": "1.5rem",
  "3xl": "1.875rem",
  "4xl": "2.25rem",
  "5xl": "3rem",
  "6xl": "3.75rem",
  "7xl": "4.5rem",
  "8xl": "6rem",
  "9xl": "8rem",
};

const components = {};

const theme = extendTheme({
  config,
  styles: {
    global: {},
  },
  fonts: {
    body: "'Oxygen', sans",
    heading: "'Lora', sans",
    // mono: "Menlo, monospace",
  },
  colors,
  components,
  sizes,
  fontSizes,
});

export default theme;
