// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medal-tally-js": () => import("./../../../src/pages/medal-tally.js" /* webpackChunkName: "component---src-pages-medal-tally-js" */),
  "component---src-pages-raffle-js": () => import("./../../../src/pages/raffle.js" /* webpackChunkName: "component---src-pages-raffle-js" */),
  "component---src-pages-record-of-race-results-js": () => import("./../../../src/pages/record-of-race-results.js" /* webpackChunkName: "component---src-pages-record-of-race-results-js" */),
  "component---src-pages-the-fair-js": () => import("./../../../src/pages/the-fair.js" /* webpackChunkName: "component---src-pages-the-fair-js" */),
  "component---src-pages-the-races-js": () => import("./../../../src/pages/the-races.js" /* webpackChunkName: "component---src-pages-the-races-js" */)
}

